@font-face {
    font-family: Graphik;
    src: url("assets/fonts/GraphikMedium.otf");
    font-weight: 500;
}

@font-face {
    font-family: Graphik;
    src: url("assets/fonts/GraphikLight.otf");
    font-weight: 300;
}

@font-face {
    font-family: Graphik;
    src: url("assets/fonts/GraphikBold.otf");
    font-weight: 700;
}

html body {
    font-family: Graphik, serif;
}

:root {
    --color-yellow: #fcee00;
    --color-main: #0e4765;
    --color-secondary: #246486;
}

.container {
    margin: 0 auto;
}

.hero {
    background-image: url("./assets/hero-sm.svg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: top;
    min-height: 336px;
}

@media (min-width: 320px) {
    .hero {
        background-image: url("./assets/hero-bg.jpg");
        min-height: 336px;
    }
}

@media (min-width: 768px) {
    .hero {
        background-image: url("./assets/hero-bg.jpg");
        min-height: 730px;
    }
}

@media (min-width: 1024px) {
    .hero {
        background-image: url("./assets/hero-bg.jpg");
        min-height: 1138px;
    }
}
@media (max-width: 768px) {
    .hero-anchor-container {
        display: none;
    }
    .btn {
        display: block !important;
    }
}
.hero-anchor-container {
    position: fixed;
    z-index: 9999;
    top: 20vh;
    right: 0;
}
.hero-anchor {
    width: 10vw;
    display: block;
    margin-bottom: 3px;
    cursor: pointer;
}
.btn {
    display: none;
    position: fixed;
    right: 20px;
    top: 20px;
    width: 40px;
    height: 40px;
    background: white;
    z-index: 9999;
    cursor: pointer;
}
.drawer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 9999;
    background: white;
    padding-top: 80px;
}
.drawer-hero-anchor {
    width: 100%;
}
/* .menu- */

.hero-video {
    width: 236px;
    height: 134px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 18px;
}

@media (min-width: 768px) {
    .hero-video {
        width: 515px;
        height: 313px;
        bottom: 36px;
    }
}

@media (min-width: 1024px) {
    .hero-video {
        width: 752px;
        height: 422px;
        bottom: 40px;
    }
}

.hero-title {
    position: absolute;
    top: 89px;
    left: 50%;
    transform: translateX(-50%);
    margin: 89px auto 0 auto;
}

.hero-logo {
    color: var(--color-main);
    font-size: 46px;
    display: flex;
    align-items: center;
    padding-left: 341px;
    padding-top: 41px;
}

.hero-logo img {
    margin-right: 5px;
}

.hero-logo small {
    font-size: 22px;
    color: gray;
}

.god-teammates {
    position: relative;
}

.god-teammates-wrapper {
    background-color: #0e4765;
    width: 100vw;
    min-height: 790px;
    position: relative;
}

.god-teammates-title {
    text-align: center;
    color: #fff;
    font-size: 60px;
    padding-top: 90px;
    padding-bottom: 55px;
    text-shadow: 0.1em 0.2em #000;
    transform: skewY(-7deg);
}

.god-teammates-disclaimer{
    color: white;
    text-align: right;
    display: block;
    margin-right: 50px;
}

.avatars {
    display: flex;
    gap: 13px;
    justify-content: center;
    margin-top: 40px;
}

.avatar {
    width: 240px;
    height: 437px;
    cursor: pointer;
}

.middle {
    background-color: #bc4b13;
    width: 100vw;
    height: 150px;
}

.middle span img {
    display: block;
    margin: 0 auto;
}

.middle-text {
    font-size: 32px;
    color: #fff;
    text-align: center;
    padding: 30px 0;
    font-weight: lighter;
}

.member {
    background-image: url("./assets/Triangle.svg");
    width: 402px;
    height: 234px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.member span {
    font-size: 36px;
    color: var(--color-main);
    text-align: center;
}

.teammates {
    position: relative;
    overflow: hidden;
}

.teammates .p {
    color: var(--color-main);
    font-size: 38px;
    text-align: center;
    padding-top: 43px;
}

.teammates-wrapper {
    display: flex;
    justify-content: center;
}

.teammates-members {
    gap: 38px;
    row-gap: 20px;
    display: grid;
    grid-template-columns: repeat(3, 402px);
    margin-top: 70px;
    position: relative;
}

.footer {
    background: var(--color-main);
}

.footer p {
    padding: 25px 0;
    text-align: center;
    color: #fff;
}

.speech-babel-background {
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.speech-babel {
    max-width: 80vw;
    width: 1136px;
    height: 629px;
    position: fixed;
    z-index: 99;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border-radius: 35px;
    background-color: #226384;

    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
}
.speech-babel.main {
    background-color: white;
}
.speech-babel.main::after {
    border-color: white transparent transparent transparent;
}
.speech-babel::after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 15px 0 15px;
    border-color: #226384 transparent transparent transparent;

    position: absolute;
    bottom: -20px;
    right: 80px;
}
@media (max-width: 768px) {
    .speech-babel {
        max-width: 95vw;
    }
    .speech-babel-content {
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
    .speech-babel .speech-babel-close {
        right: 20px !important;
        top: 15px !important;
    }
}

.speech-babel-content {
    padding: 58px 188px 0px 99px;
    height: 500px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.speech-babel-speech {
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.speech-babel-speech:last-of-type {
    margin-bottom: 100px;
}

.speech-babel-speech h3 {
    font-size: 45px;
}

.speech-babel-speech p {
    font-weight: lighter;
    font-size: 36px;
}

.speech-babel-speech span {
    font-size: 36px;
    font-weight: lighter;
    align-self: flex-end;
}

.speech-babel .speech-babel-close {
    position: absolute;
    right: 55px;
    top: 45px;
    cursor: pointer;
}

@media only screen and (max-width: 1279px) {
    .hero-logo {
        padding-left: 64px;
    }
    .avatars {
        flex-direction: column;
        align-items: center;
    }
    .god-teammates-wrapper {
        padding-bottom: 64px;
    }
    .teammates-members {
        grid-template-columns: repeat(1, 402px);
    }
}

@media (max-width: 768px) {
    .middle{
        height:200px;
    }
    .middle-text{
        font-size: 23px;
    }
    .teammates .p{
        font-size: 27px;
    }
    .member span{
        font-size: 27px;
    }
    .speech-babel-speech h3{
        font-size: 37px;
    }
    .speech-babel-speech p{
        font-size: 27px;
    }
    .speech-babel-speech span{
        font-size: 27px;
    }
}
